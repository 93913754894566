<template>
  <div class="warp" v-if="orderDetail.orderNumber">
    <div class="address-box">
      <div class="user-info"><van-icon name="location-o" />收货人：{{ orderDetail.receiver }} {{ orderDetail.receiverMobile }}</div>
      <div class="address-info">{{ orderDetail.address }}</div>
<!--      <div class="dao-hang">导航</div>-->
    </div>
    <div class="order-box">
      <div class="div-box">
        <span>订单编号：</span>
        <span>{{ orderDetail.orderNumber }}</span>
      </div>
      <div class="div-box" v-if="orderDetail.status == 3">
        <span>送水员姓名：</span>
        <span>{{ orderDetail.deliveryUserName }}</span>
      </div>
      <div class="div-box">
        <span>预计送达时间：</span>
        <span>{{ orderDetail.estimateTime || orderDetail.userTime}}</span>
      </div>
      <div class="div-box">
        <span>备注：</span>
        <span>{{ orderDetail.remarks || '无' }}</span>
      </div>
    </div>
    <div class="goods-box" v-if="goodsList.length > 0">
      <van-card
          v-for="item in goodsList"
          :key="item.orderItemId"
          style="background: #f8f7f7"
          :num="item.prodCount"
          :price="item.price"
          :title="item.prodName"
          :thumb="item.pic"
      />
    </div>

    <div class="btns">
      <button class="sure btn" v-if="orderDetail.status==3" @click="gameOver">确认送达</button>
      <button class="btn phone" :style="orderDetail.status==3 ? 'margin-left: 10px' : ''">
        <a :href="`tel:${orderDetail.receiverMobile}`" style="color: #fff;">拨打用户电话</a>
      </button>
    </div>
  </div>
</template>

<script>
import { orderDetail, orderSubmit } from '../../assets/api/order.js'
import {Toast,Dialog} from "vant";
export default {
  data() {
    return {
      orderNumber: this.$route.query.orderNumber,
      active: 0,
      loading: false,
      count: 0,
      orderDetail: {},
      goodsList: []
    }
  },
  mounted() {
    this.initDetail()
  },
  methods: {
    initDetail() {
      orderDetail(this.orderNumber).then(res => {
        if (res.data.code == 200) {
          this.orderDetail = res.data.data
          this.goodsList = res.data.data.orderItems
        }
      })
    },
    gameOver() {
      Dialog.confirm({
        title: '确认送货',
        message:'是否确认货物已送达？',
      }).then(() => {
        const params = {
          status: 4,
          orderId: this.orderDetail.orderId
        }
        orderSubmit(params).then((res) => {
          if (res.data.code == 200) {
            Toast.success('确认收货成功');
            this.$router.go(-1)
          }
        })
      })
      .catch(() => {
        // on cancel
      });

    }
  }
}
</script>

<style scoped lang="less">
.warp {
  padding: 10px;

  .address-box {
    width: calc(100% - 20px);
    color: #666;
    font-size: 12px;
    border: 1px solid #aaaaaa;
    border-radius: 8px;
    margin-top: 15px;
    padding: 5px 10px;
    position: relative;
    background: #f7f8fabd;

    .user-info {
      font-size: 16px;
      height: 32px;
      line-height: 32px;
    }
    .address-info {
      line-height: 18px;
    }
    .dao-hang {
      position: absolute;
      top: 0;
      right: 15px;
      line-height: 60px;
      font-size: 16px;
      color: #37C144;
    }
  }

  .order-box {
    width: calc(100% - 20px);
    height: auto;
    color: #666;
    font-size: 12px;
    border: 1px solid #aaaaaa;
    border-radius: 8px;
    margin-top: 15px;
    padding: 5px 10px;
    position: relative;
    background: #f7f8fabd;

    .div-box {
      display: flex;
      justify-content: space-between;
      height: 25px;
      line-height: 25px;
      color: #666;
    }
  }
  .goods-box {
    background: #f7f8fabd;
    margin-top: 15px;
    padding: 5px 10px;
    border-radius: 8px;
  }

  .btns {
    width: calc(100% - 20px);
    position: fixed;
    bottom: 10px;
    text-align: center;

    .sure {
      width: calc(40% - 10px);
    }

    .btn {
      height: 40px;
      line-height: 40px;
      border: none;
      background: #e4e5e7;
      border-radius: 8px;
      color: #333;
    }

    .phone {
      width: 60%;
      background: #ee0a24;
    }
  }
}
</style>
